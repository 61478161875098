// material-ui
import { Button, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project imports
import AuthCardWrapper from '../AuthCardWrapper';
import AuthWrapper1 from '../AuthWrapper';

import { ReactComponent as VerifyUserMail } from 'assets/icons/auth/check-mail.svg';
import AuthLeftWrapper from '../AuthLeftWrapper';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useLocation } from 'react-router-dom';
import getAxiosInstance from 'api';
import toast from 'react-hot-toast';

// ================================|| AUTH1 - Verify User ||================================ //

const VerifyUser = () => {
  const theme = useTheme();
  const location = useLocation();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const handleEmail = () => {
    getAxiosInstance(
      `businessPartner/resendActivationLink`,
      'post',
      {
        email: location?.state.email,
      },
      {
        'journey-type': 'Register',
      }
    )
      .then((res) => {
        if (res.status === 200) {
          toast.success('Email resend successfully');
        } else {
          toast.error(res.response.data.message || 'Something went wrong');
        }
      })
      .catch((error) => {
        toast.error(error.response.data);
      });
  };
  return (
    <AuthWrapper1>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ minHeight: '100vh' }}
      >
        {/* Left */}
        <AuthLeftWrapper />

        {/* Right  */}
        <Grid item container justifyContent="center" md={6} lg={6}>
          <AuthCardWrapper
          // sx={{ padding: '20px !important' }}
          >
            <Grid
              container
              spacing={3}
              justifyContent="center"
              alignItems={'center'}
              textAlign={'center'}
            >
              <Grid item xs={12}>
                <VerifyUserMail />
              </Grid>

              <Grid item xs={12} mb={2}>
                <Typography gutterBottom variant={matchDownSM ? 'h3' : 'h2'}>
                  Verify your email
                </Typography>
                <Stack direction={'column'} mt={1.5}>
                  <Stack
                    direction={'row'}
                    textAlign={'center'}
                    justifyContent={'center'}
                    gap={0.5}
                    alignItems={'flex-start'}
                    // flexWrap={'wrap'}
                  >
                    <Typography
                      gutterBottom
                      fontWeight={400}
                      variant={matchDownSM ? 'h5' : 'h4'}
                      sx={{ color: '#00182399', whiteSpace: 'nowrap' }}
                    >
                      we sent a verification email to{' '}
                    </Typography>
                    <Typography variant="h4">
                      {location?.state.email}.
                    </Typography>
                  </Stack>

                  <Typography
                    gutterBottom
                    fontWeight={400}
                    variant={matchDownSM ? 'h5' : 'h4'}
                    sx={{ color: '#00182399' }}
                  >
                    Check your inbox for next step.
                  </Typography>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <AnimateButton>
                  <Button
                    disableElevation
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    onClick={handleEmail}
                  >
                    Resend Email
                  </Button>
                </AnimateButton>
              </Grid>
            </Grid>
          </AuthCardWrapper>
        </Grid>
      </Grid>
    </AuthWrapper1>
  );
};

export default VerifyUser;
