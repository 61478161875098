import { Link } from "react-router-dom";

// material-ui
import { Box, Grid } from "@mui/material";

// project imports
import AuthPattern from "assets/images/auth/login-card.png";
import Logo from "ui-component/Logo";
import AuthSlider from "ui-component/cards/AuthSlider";
import BackgroundPattern1 from "ui-component/cards/BackgroundPattern1";

// carousel items
const items = [
  {
    title: "Access Financial Accounts for Data",
    description:
      "Connect customer accounts from over 20 banks and alternative financial institutions.",
  },
];

const AuthLeftWrapper = () => {
  return (
    <>
      <Grid
        item
        md={6}
        lg={6}
        sx={{
          position: "relative",
          alignSelf: "stretch",
          display: { xs: "none", md: "block" },
        }}
      >
        <BackgroundPattern1>
          <Grid
            item
            container
            spacing={5}
            alignItems="flex-end"
            justifyContent="center"
            sx={{
              paddingLeft: "50px",
              marginTop: "2px",
            }}
          >
            <Grid item xs={12}>
              <Link to="#" aria-label="theme-logo">
                <Logo />
              </Link>
            </Grid>

            <Grid item xs={12}>
              <Box
                component={"img"}
                sx={{
                  // height: '100%',
                  width: "100%",
                  aspectRatio: "3/2",
                  filter: "drop-shadow(-14px 17px 30px rgba(0, 24, 35, 0.06))",
                }}
                src={AuthPattern}
              />
            </Grid>

            <Grid item xs={12}>
              <Grid item container sx={{ pb: 8 }}>
                <Grid item xs={10} lg={8} sx={{ "& .slick-list": { pb: 2 } }}>
                  <AuthSlider items={items} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </BackgroundPattern1>
      </Grid>
    </>
  );
};

export default AuthLeftWrapper;
