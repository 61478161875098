// material-ui
import { Button, Grid, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project imports
import AuthCardWrapper from '../AuthCardWrapper';
import AuthWrapper1 from '../AuthWrapper';

import { ReactComponent as RegistrationSuccess } from 'assets/icons/auth/success.svg';
import AnimateButton from 'ui-component/extended/AnimateButton';
import AuthLeftWrapper from '../AuthLeftWrapper';
import { useNavigate } from 'react-router-dom';

// ================================|| AUTH1 - Verify User ||================================ //

const RegistrationSuccessful = () => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

  const navigate = useNavigate();

  const handleClick=()=>{
    navigate('/login');
  }

  return (
    <AuthWrapper1>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ minHeight: '100vh' }}
      >
        {/* Left */}
        <AuthLeftWrapper />

        {/* Right  */}
        <Grid item container justifyContent="center" md={6} lg={6}>
          <AuthCardWrapper>
            <Grid
              container
              spacing={3}
              justifyContent="center"
              alignItems={'center'}
              textAlign={'center'}
            >
              <Grid item xs={12}>
                <RegistrationSuccess />
              </Grid>

              <Grid item xs={12} mb={2}>
                <Typography gutterBottom variant={matchDownSM ? 'h3' : 'h2'}>
                  Registration Successful!{' '}
                </Typography>

                <Typography
                  gutterBottom
                  fontWeight={400}
                  variant={matchDownSM ? 'h5' : 'h4'}
                  sx={{ color: '#00182399' }}
                >
                  Thanks for verifying your email address. Now taking you to
                  e-doc online...{' '}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <AnimateButton>
                  <Button
                    disableElevation
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    onClick={handleClick}
                  >
                    Done
                  </Button>
                </AnimateButton>
              </Grid>
            </Grid>
          </AuthCardWrapper>
        </Grid>
      </Grid>
    </AuthWrapper1>
  );
};

export default RegistrationSuccessful;
