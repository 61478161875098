import React from "react";
import MuiSnackbar from "@mui/material/Snackbar";
import { Alert, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function NotificationSnackBar({ alert, handleClose }) {
  return (
    <MuiSnackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      vertical="alert"
      open={alert?.isOpen}
      autoHideDuration={6000}
      onClose={handleClose}
      color="error"
      message={alert?.message}
      TransitionComponent={"Fade"}
    >
      <Alert
        variant={alert.variant}
        severity={alert.status}
        color={alert.color}
        action={
          <>
            <IconButton
              sx={{ color: "background.paper" }}
              size="small"
              aria-label="close"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
        sx={{
          ...(alert.variant === "outlined" && {
            bgcolor: "error",
          }),
        }}
      >
        {alert?.alertMessage}
      </Alert>
    </MuiSnackbar>
  );
}
