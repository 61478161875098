import axios from "axios";
import { getUserData } from "common/getUserData";
import React, { useState } from "react";
import ReactDOM from "react-dom";
import SessionInactivePopup from "./common/SessionExpiredDialog";

const BASE_URL = process.env.REACT_APP_EDOC_API_URL || "";

function showSessionInactivePopup() {
  const div = document.createElement("div");
  document.body.appendChild(div);

  function handleClose() {
    ReactDOM.unmountComponentAtNode(div);
    document.body.removeChild(div);
    window.location.href = "/login";
  }

  ReactDOM.render(<SessionInactivePopup open={true} onClose={handleClose} />, div);
}

export default function getAxiosInstance(link, method, payload, headers) {
  let userData = getUserData();
  let requestObj = {
    method: method,
    url: `${BASE_URL}/${link}`,
    data: payload,
    headers: {
      Authorization: `Bearer ${userData?.accessToken}`,
      'app-name': process.env.REACT_APP_NAME,
      'app-version': process.env.REACT_APP_VERSION,
      ...headers,
    },
  };
  return axios(requestObj)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      if (link !== "auth/login") {
        if (err?.response?.status === 401) {
          showSessionInactivePopup();
        }
      }
      if (err.response) {
        // Return the error response
        return Promise.reject(err.response.data);
      }
      return Promise.reject(err);
    });
}