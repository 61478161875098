import PropTypes from "prop-types";

// material-ui
import { Box, useTheme } from "@mui/material";

// assets

// ===========================|| BACKGROUND GRID PATTERN 1 ||=========================== //

const BackgroundPattern1 = ({ children }) => {
  const theme = useTheme();
  return (
    <Box
      component="span"
      sx={{
        display: "flex",
        minHeight: "100vh",
        background: theme.palette.background.light,
      }}
    >
      {children}
    </Box>
  );
};

BackgroundPattern1.propTypes = {
  children: PropTypes.node,
};

export default BackgroundPattern1;
