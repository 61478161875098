import { lazy } from "react";

// project imports
import MinimalLayout from "layout/MinimalLayout";
import NavMotion from "layout/NavMotion";
import Loadable from "ui-component/Loadable";
import Register from 'views/authentication/auth/Register';
import VerifyUser from 'views/authentication/auth/VerifyUser';
import RegistrationSuccessful from 'views/authentication/auth/Register-Success';

// login routing
const AuthLogin = Loadable(
  lazy(() => import("views/authentication/auth/Login"))
);

const ConfomationVerification= Loadable(
  lazy(() => import("views/authentication/auth/ConformationEmail"))
);

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '/',
  element: (
    <NavMotion>
      <MinimalLayout />
    </NavMotion>
  ),
  children: [
    {
      path: '/',
      element: <AuthLogin />,
    },
    {
      path: '/login',
      element: <AuthLogin />,
    },
    {
      path: '/register',
      element: <Register />,
    },
    {
      path: '/verify-user',
      element: <VerifyUser />,
    },
    {
      path: '/registration-success',
      element: <RegistrationSuccessful />,
    },

    {
      path: '/activateBusinessPartnerUsers/:token',
      element: <ConfomationVerification />,
    },
  ],
};

export default LoginRoutes;
