import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// material-ui
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CountryList from 'react-select-country-list';

// third party

// project imports
import useConfig from 'hooks/useConfig';
// import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import getAxiosInstance from 'api';
import NotificationSnackBar from 'common/NotificationSnackBar';

// ============================|| Register ||============================ //

const topCountry = [
  { label: 'The Shawshank Redemption', year: 1994 },
  { label: 'The Godfather', year: 1972 },
  { label: 'The Godfather: Part II', year: 1974 },
  { label: 'The Dark Knight', year: 2008 },
  { label: '12 Angry Men', year: 1957 },
  { label: "Schindler's List", year: 1993 },
  { label: 'Pulp Fiction', year: 1994 },
];

const AuthRegister = ({ ...others }) => {
  const theme = useTheme();
  const options = CountryList().getData();
  const [registrationInfo, setRegistrationInfo] = useState({
    firstName: '',
    firstNameError: null,
    lastName: '',
    lastNameError: null,
    countryName: '',
    countryNameError: null,
    companyName: '',
    companyNameError: null,
    email: '',
    emailError: null,
    password: '',
    passwordError: null,
  });
  const navigation = useNavigate();
  // const scriptedRef = useScriptRef();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const { borderRadius } = useConfig();
  const [checked, setChecked] = useState(false);
  const [alert, setAlert] = useState({
    alertMessage: '',
    isOpen: '',
    status: '',
  });

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleClose = () => {
    setAlert({
      ...alert,
      isOpen: false,
    });
  };
  const handleChange = (name, value) => {
    let newState = { ...registrationInfo };
    if (value) {
      newState[name + 'Error'] = null;
    }
    newState[name] = value;
    setRegistrationInfo(newState);
  };

  const handleSignIn = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    const newState = { ...registrationInfo };

    if (!newState.firstName) {
      newState.firstNameError = 'First Name is required';
    } else {
      newState.firstNameError = null;
    }

    if (!newState.lastName) {
      newState.lastNameError = 'Last Name is required';
    } else {
      newState.lastNameError = null;
    }

    if (!newState.countryName) {
      newState.countryNameError = 'Please select your country';
    } else {
      newState.countryNameError = null;
    }

    if (!newState.companyName) {
      newState.companyNameError = 'Company Name is required';
    } else {
      newState.companyNameError = null;
    }

    if (!newState.email) {
      newState.emailError = 'Email is required';
    } else if (!emailRegex.test(newState.email)) {
      newState.emailError = 'Please enter a valid email';
    } else {
      newState.emailError = null;
    }

    if (!newState.password) {
      newState.passwordError = 'Password is required';
    } else if (!passwordRegex.test(newState.password)) {
      newState.passwordError =
        'Password must contain at least 8 characters, including uppercase, lowercase, number, and special character';
    } else {
      newState.passwordError = null;
    }

    if (
      !newState.firstName ||
      !newState.lastName ||
      !newState.countryName ||
      !newState.companyName ||
      !newState.email ||
      !newState.password ||
      newState.passwordError
    ) {
      setRegistrationInfo(newState);
    } else {
      // Proceed with registration
      getAxiosInstance(
        'businessPartner/registerAPIBusinessPartner',
        'post',
        {
          countryName: newState.countryName.label,
          companyName: newState.companyName,
          'acceptedT&C': checked,
          emailAddress: newState.email,
          isActive: false,
          isVerified: false,
          users: [
            {
              designation: 'Director',
              email: newState.email,
              firstName: newState.firstName,
              lastName: newState.lastName,
              password: newState.password,
            },
          ],
        },
        {
          'journey-type': 'Register',
        }
      )
        .then((res) => {
          if (res?.data?.codes === 200) {
            navigation('/verify-user', { state: { email: newState.email } });
          }
        })
        .catch((err) => {
          // if (scriptedRef.current) {
          const errorMessage =
            err.message || 'Registration failed. Please try again.';
          setAlert({
            alertMessage: errorMessage,
            isOpen: true,
            status: 'error',
          });
          // }
        });
    }
  };

  return (
    <>
      <Grid container spacing={1.5}>
        <Grid item xs={6}>
          <InputLabel>First Name </InputLabel>
          <TextField
            type="text"
            name="first-name"
            placeholder="enter first name"
            fullWidth
            hiddenLabel
            error={registrationInfo.firstNameError}
            helperText={registrationInfo.firstNameError}
            value={registrationInfo.firstName}
            onChange={(e) => handleChange('firstName', e.target.value)}
          />
        </Grid>

        <Grid item xs={6}>
          <InputLabel>Last Name </InputLabel>
          <TextField
            type="text"
            name="last-name"
            placeholder="enter last name"
            fullWidth
            hiddenLabel
            error={registrationInfo.lastNameError}
            helperText={registrationInfo.lastNameError}
            value={registrationInfo.lastName}
            onChange={(e) => handleChange('lastName', e.target.value)}
          />
        </Grid>

        <Grid item xs={12}>
          <InputLabel>Select Country</InputLabel>
          <Autocomplete
            id="select-country"
            options={options}
            value={registrationInfo.countryName}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select your country"
                mt={2}
                error={registrationInfo?.countryNameError}
                helperText={registrationInfo?.countryNameError}
              />
            )}
            onChange={(e, newValue) => handleChange('countryName', newValue)}
          />{' '}
        </Grid>
        <Grid item xs={12}>
          <InputLabel>Business Name </InputLabel>
          <TextField
            type="text"
            name="business-name"
            placeholder="enter your business name"
            fullWidth
            hiddenLabel
            error={registrationInfo.companyNameError}
            helperText={registrationInfo.companyNameError}
            value={registrationInfo.companyName}
            onChange={(e) => handleChange('companyName', e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel>Work Email </InputLabel>
          <TextField
            type="email"
            name="work-email"
            placeholder="name@workmail.com"
            fullWidth
            hiddenLabel
            error={registrationInfo.emailError}
            helperText={registrationInfo.emailError}
            value={registrationInfo.email}
            onChange={(e) => handleChange('email', e.target.value)}
          />
        </Grid>

        <Grid item xs={12}>
          <InputLabel>Password</InputLabel>
          <FormControl fullWidth error={!!registrationInfo.passwordError}>
            <OutlinedInput
              type={showPassword ? 'text' : 'password'}
              name="password"
              placeholder="password"
              value={registrationInfo.password}
              onChange={(e) => handleChange('password', e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    size="large"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              inputProps={{}}
            />
            {registrationInfo.passwordError && (
              <FormHelperText>{registrationInfo.passwordError}</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={(event) => setChecked(event.target.checked)}
                name="checked"
                color="secondary"
              />
            }
            label={
              <Stack
                direction={'row'}
                alignItems={'center'}
                flexWrap={'wrap'}
                gap={0.5}
              >
                <Typography variant="h4">
                  By clicking “Allow” you also agree to our
                </Typography>
                <Typography
                  variant="h4"
                  onClick={() =>
                    window.open(
                      'https://s3.eu-west-2.amazonaws.com/dev.e-doconline.co.uk/documents/E-Doc+Ng+Terms+%26+Conditions.pdf'
                    )
                  }
                  to="#"
                  color={'secondary'}
                >
                  Terms of Use
                </Typography>{' '}
                <Typography variant="h4"> and</Typography>{' '}
                <Typography
                  variant="h4"
                  onClick={() =>
                    window.open(
                      'https://s3.eu-west-2.amazonaws.com/dev.e-doconline.co.uk/documents/E-Doc+ng+Privacy+Policy.pdf'
                    )
                  }
                  to="#"
                  color={'secondary'}
                >
                  Privacy Policy
                </Typography>
              </Stack>
            }
          />
        </Grid>

        <Grid item xs={12}>
          <Box>
            <AnimateButton>
              <Button
                disableElevation
                fullWidth
                size="large"
                type="button"
                variant="contained"
                color="secondary"
                onClick={handleSignIn}
                disabled={!checked}
              >
                Sign in
              </Button>
            </AnimateButton>
          </Box>
        </Grid>
      </Grid>

      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'center'}
        mt={5}
        gap={0.5}
      >
        <Typography variant="h4">Already have an account?</Typography>
        <Typography
          variant="h4"
          component={Link}
          to="/login"
          color={'secondary'}
        >
          Sign in
        </Typography>
      </Stack>

      <NotificationSnackBar alert={alert} handleClose={handleClose} />
    </>
  );
};

AuthRegister.propTypes = {
  loginProp: PropTypes.number,
};

export default AuthRegister;
