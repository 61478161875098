// material-ui
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project imports
import AuthCardWrapper from '../AuthCardWrapper';
import AuthWrapper1 from '../AuthWrapper';

import { ReactComponent as AddPersonIcon } from 'assets/icons/auth/add-person.svg';
import AuthLeftWrapper from '../AuthLeftWrapper';
import AuthRegister from '../auth-forms/AuthRegister';

// ================================|| Register ||================================ //

const Register = () => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <AuthWrapper1>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ minHeight: '100vh' }}
      >
        {/* Left */}
        <AuthLeftWrapper />

        {/* Right  */}
        <Grid
          item
          container
          justifyContent="center"
          md={6}
          lg={6}
          sx={{ my: 3 }}
        >
          <AuthCardWrapper>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12}>
                <AddPersonIcon />
              </Grid>
              <Grid item xs={12} mb={4}>
                <Typography gutterBottom variant={matchDownSM ? 'h3' : 'h2'}>
                  Create your account
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <AuthRegister />
              </Grid>
            </Grid>
          </AuthCardWrapper>
        </Grid>
      </Grid>
    </AuthWrapper1>
  );
};

export default Register;
