import { lazy } from "react";

import MainLayout from 'layout/MainLayout';

import { Navigate } from 'react-router-dom';
import Loadable from "ui-component/Loadable";
import AuthGuard from 'utils/route-guard/AuthGuard';
import Register from 'views/authentication/auth/Register';
import KYC from 'views/KYC';

const Reports = Loadable(lazy(() => import("views/reports")));
const ApiBusinessPartner = Loadable(
  lazy(() => import("views/api-business-partner"))
);
const Wallet = Loadable(lazy(() => import("views/wallet")));
const ChoosePlan = Loadable(lazy(() => import("views/choose-plan")));
const Settings = Loadable(lazy(() => import("views/settings")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '/reports',
      element: <Reports />,
    },
    {
      path: '/api-business-partner',
      element: <ApiBusinessPartner />,
    },
    {
      path: '/kyc',
      element: <KYC />,
    },
    {
      path: '/wallet',
      element: <Wallet />,
    },
    { path: '*', element: <Navigate to="/api-business-partner" replace /> },
    {
      path: '/settings',
      element: <Settings />,
    },
  ],
};

export default MainRoutes;
