import PropTypes from 'prop-types';

// material-ui
import { Grid, Typography } from '@mui/material';

// third-party
import Slider from 'react-slick';
import { useTheme } from '@emotion/react';

const AuthSlider = ({ items }) => {
  const theme = useTheme();
  const settings = {
    autoplay: true,
    arrows: false,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Slider {...settings}>
      {items.map((item, i) => (
        <Grid
          key={i}
          container
          direction="column"
          alignItems="center"
          spacing={2}
          textAlign="left"
        >
          <Grid item>
            <Typography
              sx={{
                fontSize: '40px',
                fontWeight: 700,
                color: 'grey.900',
              }}
            >
              {item.title
                .split(' ')
                .map((word, i) =>
                  word === 'Financial' ? (
                    <span
                      style={{ color: theme.palette.secondary.main }}
                      key={i}
                    >
                      {word}
                    </span>
                  ) : (
                    word
                  )
                )
                .reduce(
                  (prev, curr, i) => (i === 0 ? [curr] : [prev, ' ', curr]),
                  []
                )}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="h4"
              fontWeight={400}
              sx={{ color: '#001823CC' }}
            >
              {item.description}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Slider>
  );
};

AuthSlider.propTypes = {
    items: PropTypes.array.isRequired
};

export default AuthSlider;